.main-content {
    text-align: center;
    padding: 50px 20px;
    max-width: 500px;
    margin: 0 auto;
  }
  
  .main-content h1 {
    font-size: 2.5rem;
    font-weight: bold;
    margin-bottom: 40px;
    margin-top: 60px;
  }
  
  .main-content p {
    font-size: 1rem;
    color: #666;
    margin-bottom: 40px;
    line-height: 2rem;
  }
  
  .explore-button {
    background-color: #00aaff;
    color: white;
    border: none;
    padding: 15px 30px;
    font-size: 1rem;
    border-radius: 25px;
    cursor: pointer;
  }
  
  .explore-button:hover {
    background-color: #008ecc;
  }
  


/* Metrics Section -----------------------------------------------------------------------------------------------------------*/

  .metrics-content {
    text-align: center;
    padding: 90px 20px;
    margin: 0 auto;
    background-color: #f4f6f9;
    /* opacity: 50%; */
  }
  
  .metrics-content ul {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    justify-content: space-around; /* To distribute the items evenly */
  }
  
  .metrics-content li {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .metric {
    position: relative;
    text-align: center;
  }
  
  .metric-value {
    font-size: 3rem;
    font-weight: bold;
    color: #00aaff;
  }
  
  .metric-desc {
    font-size: 1rem;
    color: #666;
    margin-top: 10px;
  }
  
  .divider {
    width: 1px;
    height: 80px; 
    background-color: #00aaff;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 220%; /* Adjust the position */
    transform: translateX(-50%);
  }

  .metrics-content li:last-child .divider {
    display: none; /* Hide the divider for the last item */
  }


/* Event Section ------------------------------------------------------------------------------------------------------------*/

  .div-header h3 {
    font-size: 2rem;
    /* margin-bottom: 5px; */
    margin-top: 80px;
    text-align: center;
    font-weight: 300;
  }
  
  .event-section {
    display: flex;
    justify-content: space-between;
    padding: 10px 20px;
    margin-bottom: 100px;
  }

  .event-description {
    flex: 1;
    padding-right: 20px;
    padding-left: 100px;
    max-width: 500px;
  }
  
  .event-description h2 {
    font-size: 2rem;
    margin-bottom: 20px;
    text-align: center;
  }
  
  .event-description h3 {
    font-size: 2rem;
    margin-bottom: 20px;
    font-weight: bold;
    max-width: 400px;
  }
  
  .event-description p {
    font-size: 1rem;
    color: #666;
    margin-bottom: 20px;
    line-height: 1.5rem;
    margin-top: 40px;
  }
  
  .event-description ul {
    font-size: 1rem;
    list-style: none;
    padding: 0;
    margin: 0 0 20px 0;
    color: #666;
    line-height: 1.5rem;
    margin-bottom: 40px;
  }
  
  .event-description li {
    margin-bottom: 10px;
    position: relative;
    padding-left: 20px;
  }
  
  .event-description li::before {
    content: '•';
    position: absolute;
    left: 0;
    color: #00aaff;
    font-size: 2rem; /* Adjust size as needed */
    line-height: 1;
  }
  
  .event-description .register-button {
    background-color: #00aaff;
    color: white;
    border: none;
    padding: 15px 30px;
    font-size: 1rem;
    border-radius: 25px;
    cursor: pointer;
  }

  .event-description .register-button:hover {
      background-color: #008ecc;
  }
  
  .event-image {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .image-stack {
    position: relative;
    width: 350px; /* Adjust each card */
    height: 300px; /* Adjust each card */
  }
  
  .image-card {
    position: absolute;
    border-radius: 20px;
    overflow: hidden;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.3);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  /* Images fill the cards completely */
  .image-card img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 20px;
  }
  
  /* Adjust sizes and positions */
  .image-card:nth-child(1) {
    width: 100%;
    height: 100%; 
    z-index: 3; /* To make it appear on top */
  }
  
  .image-card:nth-child(2) {
    width: 90%;
    height: 90%;
    top: 10px;
    left: 80px; /* Adjust the middle card to the right */
    z-index: 2; /* To make it appear in the middle */
  }
  
  .image-card:nth-child(3) {
    width: 80%;
    height: 80%;
    top: 20px;
    left: 150px; /* Adjust the bottom card to the right */
    z-index: 1; /* To make it appear at the bottom */
  }

/* About Section -----------------------------------------------------------------------------------------------------------*/

  .about-content {
    text-align: center;
    padding: 90px 20px;
    margin: 0 auto;
    background-color: #f4f6f9;
    display: flex;
    flex-direction: column;
    /* align-items: center; */
  }

  .div-header2 h3 {
    font-size: 2rem;
    margin-bottom: 80px;
    margin-top: 0px;
    text-align: center;
    font-weight: 300;
  }
  
  .about-us {
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 50px 20px;
  }
  
  .team-section {
    display: flex;
    /* flex-direction: column; */
    justify-content: center;
    align-items: center;
    position: relative;
    height: 400px;
    /* width: 100%; */
  }
  
  .team-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 1px solid #ddd;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin: 20px;
    width: 200px;
  }

  .team-card2 {
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 1px solid #ddd;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin: 20px;
    width: 200px;
  }

  .team-card3 {
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 1px solid #ddd;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin: 20px;
    width: 200px;
  }
  
  .team-image {
    width: 100%;
    height: 150px;
    object-fit: cover;
  }
  
  .team-info {
    padding: 20px;
    text-align: center;
  }
  
  .team-info h4 {
    margin-bottom: 10px;
  }
  
  .profile-pic {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    margin-top: 10px;
  }
  
  .about-description {
    max-width: 500px;
    text-align: left;
    flex: 1;
    padding-right: 20px;
    padding-left: 100px;
    margin-bottom: 40px;
  }
  
  .about-description h3 {
    font-size: 2rem;
    margin-bottom: 20px;
    max-width: 400px;
  }
  
  .about-description p {
    font-size: 1rem;
    color: #666;
    margin-bottom: 20px;
    line-height: 1.5rem;
    margin-top: 40px;
    margin-bottom: 40px;
  }
  
  .join-button {
    background-color: #00aaff;
    color: white;
    border: none;
    padding: 15px 30px;
    font-size: 1rem;
    border-radius: 25px;
    cursor: pointer;
  }
  
  .join-button:hover {
    background-color: #008ecc;
  }

/* Partnership Section ------------------------------------------------------------------------------------------------------*/

  .partnership-content {
    text-align: center;
    padding: 90px 20px;
    margin: 0 auto;
    /* background-color: #f4f6f9; */
    /* opacity: 50%; */
  }
  
  .partnership-content ul {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    justify-content: space-around; /* To distribute the items evenly */
  }
  
  .partnership-content li {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .partnership {
    position: relative;
    text-align: center;
  }
  
  .partnership-value {
    font-size: 3rem;
    font-weight: bold;
    color: #00aaff;
  }
  
  .partnership-desc {
    font-size: 1rem;
    color: #666;
    margin-top: 10px;
  }

  .profile-logo {
    width: 140px;
    height: 100px;
    /* border-radius: 50%; */
    /* margin-top: 10px; */
  }

  .profile-logo2 {
    width: 160px;
    height: 100px;
    /* border-radius: 50%; */
    /* margin-top: 10px; */
  }

  /* Contact Section------------------------------------------------------------------------------------------------------ */

.contact-content {
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
  padding: 50px 20px;
  background-color: #f9f9f9;
}

.contact-form, .subscribe-section {
  background: #fff;
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.contact-form h3, .subscribe-section h3 {
  font-size: 1.5rem;
  margin-bottom: 20px;
}

.contact-form form {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
}

.contact-form input, .contact-form textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 1rem;
}

.contact-form textarea {
  grid-column: span 2;
  height: 150px;
}

.contact-form button {
  grid-column: span 2;
  background-color: #00aaff;
  color: white;
  border: none;
  padding: 15px 0;
  font-size: 1rem;
  border-radius: 25px;
  cursor: pointer;
  text-align: center;
}

.contact-form button:hover {
  background-color: #008ecc;
}

.subscribe-section {
  max-width: 300px;
  text-align: center;
}

.subscribe-section p {
  margin-bottom: 20px;
  font-size: 1rem;
  color: #666;
}

.subscribe-section input {
  width: calc(100% - 140px);
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 25px 0 0 25px;
  font-size: 1rem;
}

.subscribe-section button {
  background-color: #00aaff;
  color: white;
  border: none;
  padding: 10px 20px;
  font-size: 1rem;
  border-radius: 0 25px 25px 0;
  cursor: pointer;
}

.subscribe-section button:hover {
  background-color: #008ecc;
}

.subscribe-input-group {
  display: flex;
  justify-content: center;
  align-items: center;
}

  
/* Mobile Styling........................................................................................................... */

@media (max-width: 768px) {
  .main-content {
    padding: 20px;
    margin-top: 10px;
    margin-bottom: 20px;
  }
  
  .main-content h1 {
    font-size: 2.5rem;
  }

  .metrics-content ul {
    flex-direction: row;
    /* align-items: center; */
    justify-content: space-around;
  }

  .metrics-content li {
    /* margin-bottom: 20px;
    font-size: small; */
    margin-bottom: 0;
  }

  .metric-value {
    font-size: 2rem; /* Smaller font size for mobile */
  }

  .metric-desc {
    font-size: 0.7rem; /* Smaller font size for mobile */
  }

  .divider {
    display: none; /* Hide the divider for mobile view */
  }

  .event-section {
    flex-direction: column; /* Change the direction of the event section */
    align-items: center;
  }

  .event-description{
    order: 2; /* Order of event description */
    padding: 0 20px;
    margin-bottom: 20px;
  }

  .image-stack {
    order: 1; /* Order of image stack */
    width: 100%;
    height: auto;
    margin-bottom: 20px;
  }

  .image-card:nth-child(1) {
    width: 80%;
    height: auto;
    z-index: 1;
    position: relative;
    margin: 0 auto;
  }

  .image-card:nth-child(2),
  .image-card:nth-child(3) {
    display: none;
  }

  .div-header2 h3 {
    margin-bottom: auto;
    margin-top: 0px;
  }

  .about-us {
    flex-direction: column;
    margin-top: auto;
    /* display: flex; */
  }

  .team-section {
    flex-direction: column;
    align-items: center;
    height: auto;
    margin-top: auto;
    /* display: table-column; */
  }

  .team-card,
  .team-card2,
  .team-card3 {
    width: 250px; /* Adjust the width to make the cards smaller */
    margin: 30px;
    height: auto; /* Allow the height to adjust based on content */
    /* overflow: auto; Add scroll bar if content is too long */
  }

  .team-image {
    /* display: none; Hide the image for mobile view */
    height: 150px; /*Adjust the height to match the smaller width */
  }

  .team-info {
    flex-direction: column;
    padding: 10px; /* Adjust padding to fit smaller cards */
  }

  .team-info h4 {
    order: 2; /* Order of team member name */
    /* margin-bottom: 10px; */
  }

  .team-info p {
    order: 3; /* Order of team member description */
    /* margin-bottom: 10px; */
  }

  .profile-pic {
    order: 1; /* Order of profile picture */
    width: 100px;
    height: 100px;
  }

  .about-description {
    padding: 0 5px;
    margin-bottom: 20px;
  }

  /* .partnership-content ul {
    flex-direction: column;
    align-items: center;
  }

  .partnership-content li {
    margin-bottom: 20px;
  } */

  .partnership-content ul {
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
  }

  .partnership-content li {
    margin-bottom: 0;
  }

  .profile-logo {
    width: 100px;
    height: 70px;
    margin-left: 20px;
  }

  .profile-logo2 {
    width: 90px;
    height: 60px;
  }

  .divider {
    display: none;
  }
}
