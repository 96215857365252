.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    background-color: white;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  @media (min-width: 769px) {
    .header.sticky {
      position: fixed;
      top: 0;
      width: 100%;
      z-index: 1000;
    }
  }
  
  .logo {
    display: flex;
    align-items: center;
  }
  
  .logo h1 {
    display: flex;
    align-items: center;
  }

  .logo-image {
    height: 40px; /* Adjust the height as needed */
    width: auto; /* Maintain aspect ratio */
  }
  
  .separator {
    flex-grow: 1;
    height: 1px;
    /* background-color: #e0e0e0; */
    margin: 0 20px;
  }
  
  .navigation ul {
    display: flex;
    list-style: none;
    margin: 0;
    padding: 0;
  }
  
  .navigation li {
    margin-right: 60px;
  }
  
  .navigation a {
    text-decoration: none;
    color: black;
    font-size: 1rem;
    font-weight: normal;
  }

  .navigation a:hover {
    color: #00aaff;
  }
  
  .hamburger-menu {
    display: none;
    flex-direction: column;
    justify-content: space-between;
    height: 20px;
    cursor: pointer;
  }
  
  .hamburger-menu div {
    width: 25px;
    height: 3px;
    background-color: gray;
  }
  

  /* Mobile Styling....................................................................................................... */

/* Add media queries */
@media (max-width: 768px) {
  .navigation ul {
    display: none; /* Hide navigation on smaller screens */
  }
  
  .hamburger-menu {
    display: flex;
  }
}

@media (min-width: 769px) {
  .hamburger-menu {
    display: none; /* Hide hamburger menu on larger screens */
  }
}

.header {
  position: static;
}

/* .logo {
  margin-left: 0%;
  align-items: ;
} */

/* Add styles for the mobile navigation menu */
.mobile-nav {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 60px; /* Below the header */
  right: 0;
  background-color: white;
  width: 200px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 0;
}

.mobile-nav ul {
  list-style: none; /* Remove bullet points */
  padding: 0; /* Remove default padding */
  margin: 0; /* Remove default margin */
}

.mobile-nav li {
  border-bottom: 1px solid #e0e0e0;
  padding: 20px 30px;
  width: 100%; /* Ensure the list items span the full width */
  box-sizing: border-box; /* Include padding in the element's total width and height */
}

.mobile-nav li:last-child {
  border-bottom: none; /* Remove border from the last item */
}

.mobile-nav a {
  color: black;
  text-decoration: none; /* Remove underline from links */
  display: block; /* Ensure the entire area is clickable */
  width: 100%; /* Ensure the link spans the full width of the list item */
}
