.footer {
    padding: 20px 0;
    background-color: white;
  }
  
  .footer__line {
    width: 100%;
    height: 1px;
    background-color: #e0e0e0;
    margin-bottom: 20px;
  }
  
  .footer__content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .footer__section {
    flex: 1;
    text-align: center;
  }
  
  .footer__icons {
    display: flex;
    justify-content: center;
    gap: 15px;
  }
  
  .footer__icons a {
    color: inherit;
    text-decoration: none;
    font-size: 1.5rem;
  }
  

  /* Mobile Styling........................................................................................................ */

@media (max-width: 768px) {
  .footer__content {
    flex-direction: column;
  }

  .footer__section {
    margin-bottom: 20px;
  }

  .footer__icons {
    justify-content: center;
  }
}